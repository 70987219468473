import React, { lazy, useEffect } from "react";
import "./App.css";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));
const Join = lazy(() => import("./pages/Join"));
const Confirm = lazy(() => import("./pages/Confirm"));
const TryItOut = lazy(() => import("./pages/TryItOut"));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
const token = checkAuth();

function App() {
	useEffect(() => {
		// 👆 daisy UI themes initialization
		themeChange(false);

		// Clarity tracking script
		if (process.env.REACT_APP_ENV_TYPE === "production") {
			const script = document.createElement("script");
			script.type = "text/javascript";
			script.async = true;
			script.innerHTML = `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "gnacoqr7uw");
       `;
			document.head.appendChild(script);

			return () => {
				document.head.removeChild(script);
			};
		}

	}, []);

	return (
		<>
			<Router>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route
						path="/forgot-password"
						element={<ForgotPassword />}
					/>
					<Route path="/register" element={<Register />} />
					<Route path="/documentation" element={<Documentation />} />
					<Route path="/join" element={<Join />} />
					<Route path="/confirm" element={<Confirm />} />
					<Route path="/try" element={<TryItOut />} />

					{/* Place new routes over this */}
					<Route path="/app/*" element={<Layout />} />

					<Route
						path="*"
						element={
							<Navigate
								to={token ? "/app/dashboard" : "/join"}
								replace
							/>
						}
					/>
				</Routes>
			</Router>
		</>
	);
}

export default App;
